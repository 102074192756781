import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TransactionArea extends Component<{}, {}> {
  render() {
    return (
      <div className="transection-area pd-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <div className="section-title">
                <h6 className="subtitle subtitle-thumb">Transection Process</h6>
                <h2 className="title">Recently Best Transections</h2>
                <p>Our bank, underscoring its strong and resilient transaction banking.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="transection-details">
                <div className="row no-gutters">
                  <div className="col-lg-2 col-md-3">
                    <h4>Transection</h4>
                    <div className="nav flex-column nav-pills">
                      <a className="nav-link active" data-toggle="pill" href="#withdraw">
                        Withdraw
                      </a>
                      <a className="nav-link" data-toggle="pill" href="#deposit">
                        Deposit
                      </a>
                      <a className="nav-link" data-toggle="pill" href="#invester">
                        New Invester
                      </a>
                      <a className="nav-link" data-toggle="pill" href="#invester2">
                        Running Invest
                      </a>
                      <a className="nav-link" data-toggle="pill" href="#invester3">
                        New Invester2
                      </a>
                      <a className="nav-link" data-toggle="pill" href="#invester4">
                        Running Invest3
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-10 col-md-9">
                    <div className="tab-content">
                      <div className="tab-pane fade show active" id="withdraw">
                        <div className="table-responsive">
                          <table className="transection-table table table-hover">
                            <thead>
                              <tr>
                                <th scope="col">Person</th>
                                <th scope="col">Name</th>
                                <th scope="col">Date</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Currency</th>
                                <th scope="col">Date</th>
                                <th scope="col">Detailes</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="table-space">
                                <td className="h-30" />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/01.jpg" alt="img" />
                                </th>
                                <td>Matteo Branch</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                              <tr className="table-space">
                                <td />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/02.jpg" alt="img" />
                                </th>
                                <td>Stewart Taylor</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                              <tr className="table-space">
                                <td />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/03.jpg" alt="img" />
                                </th>
                                <td>Kasey Williamson</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                              <tr className="table-space">
                                <td />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/04.jpg" alt="img" />
                                </th>
                                <td>Zarah Ratcliffe</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="deposit">
                        <div className="table-responsive">
                          <table className="transection-table table table-hover">
                            <thead>
                              <tr>
                                <th scope="col">Person</th>
                                <th scope="col">Name</th>
                                <th scope="col">Date</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Currency</th>
                                <th scope="col">Date</th>
                                <th scope="col">Detailes</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="table-space">
                                <td className="h-30" />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/02.jpg" alt="img" />
                                </th>
                                <td>Stewart Taylor</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                              <tr className="table-space">
                                <td />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/03.jpg" alt="img" />
                                </th>
                                <td>Kasey Williamson</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                              <tr className="table-space">
                                <td />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/01.jpg" alt="img" />
                                </th>
                                <td>Matteo Branch</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                              <tr className="table-space">
                                <td />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/04.jpg" alt="img" />
                                </th>
                                <td>Zarah Ratcliffe</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="invester">
                        <div className="table-responsive">
                          <table className="transection-table table table-hover">
                            <thead>
                              <tr>
                                <th scope="col">Person</th>
                                <th scope="col">Name</th>
                                <th scope="col">Date</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Currency</th>
                                <th scope="col">Date</th>
                                <th scope="col">Detailes</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="table-space">
                                <td className="h-30" />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/02.jpg" alt="img" />
                                </th>
                                <td>Stewart Taylor</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                              <tr className="table-space">
                                <td />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/03.jpg" alt="img" />
                                </th>
                                <td>Kasey Williamson</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                              <tr className="table-space">
                                <td />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/04.jpg" alt="img" />
                                </th>
                                <td>Zarah Ratcliffe</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="invester2">
                        <div className="table-responsive">
                          <table className="transection-table table table-hover">
                            <thead>
                              <tr>
                                <th scope="col">Person</th>
                                <th scope="col">Name</th>
                                <th scope="col">Date</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Currency</th>
                                <th scope="col">Date</th>
                                <th scope="col">Detailes</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="table-space">
                                <td className="h-30" />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/03.jpg" alt="img" />
                                </th>
                                <td>Kasey Williamson</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                              <tr className="table-space">
                                <td />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/04.jpg" alt="img" />
                                </th>
                                <td>Zarah Ratcliffe</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                              <tr className="table-space">
                                <td />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/01.jpg" alt="img" />
                                </th>
                                <td>Matteo Branch</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                              <tr className="table-space">
                                <td />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/02.jpg" alt="img" />
                                </th>
                                <td>Stewart Taylor</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="invester3">
                        <div className="table-responsive">
                          <table className="transection-table table table-hover">
                            <thead>
                              <tr>
                                <th scope="col">Person</th>
                                <th scope="col">Name</th>
                                <th scope="col">Date</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Currency</th>
                                <th scope="col">Date</th>
                                <th scope="col">Detailes</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="table-space">
                                <td className="h-30" />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/03.jpg" alt="img" />
                                </th>
                                <td>Kasey Williamson</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                              <tr className="table-space">
                                <td />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/01.jpg" alt="img" />
                                </th>
                                <td>Matteo Branch</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                              <tr className="table-space">
                                <td />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/02.jpg" alt="img" />
                                </th>
                                <td>Stewart Taylor</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                              <tr className="table-space">
                                <td />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/04.jpg" alt="img" />
                                </th>
                                <td>Zarah Ratcliffe</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="invester4">
                        <div className="table-responsive">
                          <table className="transection-table table table-hover">
                            <thead>
                              <tr>
                                <th scope="col">Person</th>
                                <th scope="col">Name</th>
                                <th scope="col">Date</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Currency</th>
                                <th scope="col">Date</th>
                                <th scope="col">Detailes</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="table-space">
                                <td className="h-30" />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/04.jpg" alt="img" />
                                </th>
                                <td>Zarah Ratcliffe</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                              <tr className="table-space">
                                <td />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/01.jpg" alt="img" />
                                </th>
                                <td>Matteo Branch</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                              <tr className="table-space">
                                <td />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/02.jpg" alt="img" />
                                </th>
                                <td>Stewart Taylor</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                              <tr className="table-space">
                                <td />
                              </tr>
                              <tr>
                                <th scope="row">
                                  <img src="content/assets/store/investers/03.jpg" alt="img" />
                                </th>
                                <td>Kasey Williamson</td>
                                <td>Oct 24,2018</td>
                                <td>$9,00,000.00</td>
                                <td>Dollar</td>
                                <td>001 Days Ago</td>
                                <td>
                                  <Link className="btn initiate-scripts" to={'/services'}>
                                    View Detailes
                                  </Link>
                                </td>
                              </tr>
                              <tr className="table-space">
                                <td />
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TransactionArea;
